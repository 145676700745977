/**
 * 현재 달력이 지원하는 날짜 목록
 */
export const MONTH = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
] as const;

/**
 * 현재 달력이 지원하는 연도 목록
 */
export const YEAR = [
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
] as const;
