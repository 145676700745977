export const SCHEDULE_COLOR = [
  {
    code: "#FEE6C9",
  },
  {
    code: "#FFD9D9",
  },
  {
    code: "#D2F0FF",
  },
  {
    code: "#E8FEC9",
  },
] as const;
